import React, { useState } from 'react';
import { useGlobalContext } from '../../../../custom-hooks/Context'; 
import { NavLink, useHistory } from "react-router-dom";
import { useCustomToast } from '../../../toast/useCustomToast';
import axios from '../../../../utils/axiosInstance';
import { FORGOT_PASSWORDS } from '../../../../utils/constants';

function ForgotPass() {
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const history = useHistory();
    const showToast = useCustomToast();

    const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent the default form submission behavior
      setLoading(true); // Set loading state to true before making the API call

      try {
          const response = await axios.post(FORGOT_PASSWORDS, { phone });
          
          // Check the API response and perform necessary actions
          if (response.status === 200) {
              setSuccessMessage(response.data.message);
              showToast(response.data.message, 'success');
              
              // Assuming the API request is successful, navigate to otpVerification
              history.push("/resetPassword");
          } else {
              setSuccessMessage(''); // Reset success message if not successful
          }
      } catch (error) {
          // Display an error toast with the API response message
         
        if (error.response) {
            const { status, message, errors } = error.response.data;
            if (status === "Error") {
                if (message && errors.length === 0) {
                    showToast(message, 'error');
                } else if (message && errors.length > 0) {
                    showToast(errors[0], 'error');
                }
            }
        }
          setSuccessMessage('');
      } finally {
          setLoading(false); // Set loading state to false after handling the API call
      }
  };

    return (
        <div className='md:m-8 my-8 overflow-hidden'>
            <div className="md:p-16 py-16 px-8 md:bg-bg-green md:border-[#00BD7A40] bg-white border-white rounded-3xl border">
                <div className='w-[350px] mt-6'>
                    <form onSubmit={handleSubmit}>
                        <p className='text-gray-700 text-lg mb-2'>Phone</p>
                        <input
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                            placeholder='Enter your phone number'
                            className='md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14 w-full mb-6 p-4'
                        />
                        <div className='flex justify-center'>
                            <button
                                type='submit'
                                className="bg-color1 border rounded-lg h-14 w-full text-white mx-auto"
                                disabled={loading}
                            >
                                {loading ? "Sending..." : "Reset Password"}
                            </button>
                        </div>
                        <div className='flex justify-center mt-6'>
                            <NavLink to="/signin">
                                <p className='text-gray-500 text-xl font-xl'>
                                    <i className="fa-solid fa-left-long px-4"></i>Back to Login
                                </p>
                            </NavLink>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ForgotPass;
