import axios from "axios";

// Retrieve token from session storage
const token = sessionStorage.getItem("token");

// Define base URLs for different environments
const baseURLs = `${process.env.REACT_APP_API_BASE_URL}/api`;

// Create an Axios instance with the appropriate base URL
const axiosInstance = axios.create({
  baseURL: baseURLs,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add Authorization header if a token is present
if (token) {
  axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
}

export default axiosInstance;
