import React, { useState } from 'react';
import axios from '../../../../utils/axiosInstance';
import { useCustomToast } from '../../../toast/useCustomToast';

function ChangeEmailModal({ closeModal, currentEmail }) {
    const [email, setEmail] = useState('');
    const showToast = useCustomToast();
    const [loading, setLoading] = useState(false);

    const handleProceedBtn = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            await axios.put('onboarding/edit-email', {
                current_email: currentEmail,
                new_email: email
            });
            setLoading(false);
            closeModal();
        } catch (error) {
            if (error.response) {
                const { status, message, errors } = error.response.data;
                if (status === "Error") {
                    if (message && (!errors || Object.keys(errors).length === 0)) {
                        showToast(message, 'error');
                    } else if (errors && errors.message) {
                        showToast(errors.message, 'error');
                    }
                }
                setLoading(false);
            }
            setLoading(false);
        }
    };

    return (

        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
            <div className="bg-white md:p-16 py-16 px-8 md:bg-bg-green md:border-border-primary rounded-3xl border">
                <div className="w-[350px] mt-6">
                    <div className="flex flex-col text-center">
                        <div className="mb-2 text-center">
                            <p className="font-bold text-2xl text-[#06092D]">Change Email</p>
                        </div>
                        <div>
                            <p>One Time Password will be sent to this Email</p>
                        </div>
                        <form onSubmit={handleProceedBtn} className="text-lg">
                            <div className="mt-6">
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    placeholder="Enter your email"
                                    className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14 w-full p-4"
                                />
                            </div>
                            <div className="mt-8">
                                <button type='submit' className="bg-color1 rounded-lg h-14 w-full text-white">

                                    {loading && (
                                        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                            <div className='loader'></div>
                                        </div>
                                    )}
                                    {loading ? 'Sending...' : 'Receive Otp'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangeEmailModal;
