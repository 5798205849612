import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCustomToast } from '../../../toast/useCustomToast';
import { startLoginUser } from '../../../../actions/auth2';
import { useGlobalContext } from '../../../../custom-hooks/Context';
import { POST_SIGNIN_RESEND_OTP } from '../../../../utils/constants';
import axiosInstance from '../../../../utils/axiosInstance';
import { logDev } from '../../../../utils/logger';

function Contact() {
  const [timeLeft, setTimeLeft] = useState(600);
  const { phoneNumber, password } = useGlobalContext();
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [resendButtonDisabled, setResendButtonDisabled] = useState(true);
   const history = useHistory();
  const dispatch = useDispatch();
  const showToast = useCustomToast();
  const [loading, setLoading] = useState(false);

  // Redirect to login if phoneNumber or password is missing
  useEffect(() => {
    if (!phoneNumber || !password) {
      history.push('/login');
    }
  }, [phoneNumber, password, history]);

  const handleOtpChange = (index, value) => {
    if (value >= 0 && value <= 9) {
      const newOtpDigits = [...otp];
      newOtpDigits[index] = value;
      setOtp(newOtpDigits);

      if (index < newOtpDigits.length - 1 && value !== '') {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setResendButtonDisabled(false);
    }
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const isOtpComplete = otp.every((digit) => digit !== '');

  useEffect(() => {
    for (let i = 0; i < otp.length; i++) {
      if (!otp[i]) {
        document.getElementById(`otp-input-${i}`).focus();
        break;
      }
    }
  }, [otp]);

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && index > 0 && !otp[index]) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    logDev('Submitting OTP:', otp);
    if (isOtpComplete) {
      const enteredOtp = otp.join('');
      logDev('OTP complete. Entered OTP:', enteredOtp);

      try {
        const payload = {
          code: enteredOtp,
          phone: phoneNumber,
          otp_type: 'otp',
          password,
        };

        const response = await dispatch(startLoginUser(payload, history));
        logDev('Response from login:', response);

        const { error, data } = response;
        if (data) {
          showToast(data.message, 'success');
        }
        if (error) {
          if (error.errors && error.errors.length > 0) {
            error.errors.forEach((errMsg) => showToast(errMsg, 'error'));
          } else {
            showToast(error.message, 'error');
          }
        }

      } catch (error) {
        logDev('Error during login:', error);
        if (error.response) {
          const { status, message, errors } = error.response.data;
          if (status === "Error") {
            if (message && errors.length === 0) {
              showToast(message, 'error');
            } else if (message && errors.length > 0) {
              showToast(errors[0], 'error');
            }
          }
        } else {
          showToast(error.message, 'error');
        }
      } finally {
        setLoading(false);
      }
    } else {
      showToast('Please enter all 6 OTP digits.', 'error');
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      const payload = {
        phone: phoneNumber
      };

      const response = await axiosInstance.post(POST_SIGNIN_RESEND_OTP, payload);
      const responseData = response.data;

      if (responseData.status === 'Successful') {
        setTimeLeft(600);
        setResendButtonDisabled(true);
        showToast(responseData.message, 'success');
      }
    } catch (error) {
      logDev('Error during OTP resend:', error);
      if (error.response) {
        const { status, message, errors } = error.response.data;
        if (status === "Error") {
          if (message && errors.length === 0) {
            showToast(message, 'error');
          } else if (message && errors.length > 0) {
            showToast(errors[0], 'error');
          }
        }
      } else {
        showToast(error.message, 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='md:m-8 my-8 overflow-hidden'>
      <div className='md:p-16 py-16 px-8 md:bg-bg-green md:border-[#00BD7A40] bg-white border-white rounded-3xl border'>
        <div className='text-deep-green font-bold text-center'>
          <p className='text-2xl'>Verify your OTP</p>
          <p className='text-gray-500 text-xl font-thin w-[360px]'>
            We sent OTP to the number/email attached to your account
          </p>
        </div>
        <div className='w-[350px] mt-6 flex items-center justify-center'>
          <form onSubmit={handleSubmit}>
            <p className='text-gray-700 text-lg mb-2'>Your 6-Digit Code</p>
            {otp.map((value, index) => (
              <input
                key={index}
                type='tel'
                value={value}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                min='0'
                max='9'
                maxLength='1'
                required
                id={`otp-input-${index}`}
                tabIndex={index + 1}
                className='border md:bg-bg-green bg-white border-border-primary h-16 w-14 text-center m-2 rounded-lg p-4'
              />
            ))}
            <div className='flex justify-center mt-2'>
              <button
                className={`bg-color1 border rounded-lg h-14 w-full text-white mx-auto ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                type='submit'
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
        <div className='text-color1 mt-4 text-right w-[350px]'>{formatTime(timeLeft)}</div>
        <div className='flex justify-center'>
          <p>
            I didn't get the code?{' '}
            {resendButtonDisabled ? (
              'Resend OTP'
            ) : (
              <button className='text-color1' onClick={handleResendOtp} style={{ cursor: 'pointer' }}>
                Resend OTP
              </button>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
