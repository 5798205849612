import React, { useEffect, useState } from "react";
import axios from "../../../utils/axiosInstance";
import WalletInfo from "../../dashboard/WalletInfo";
import WalletBreakDown from "../../dashboard/WalleBreakdown";
import WalletLogs from "./WalletLog";
import { ThreeDots } from 'svg-loaders-react';
import { GET_MAIN_BALANCE } from "../../../utils/constants";
import { GET_MAIN_CURRENT } from "../../../utils/constants";
import { GET_MAIN_DEPOSIT } from "../../../utils/constants";
import { GET_MAIN_WITHDRAWAL } from "../../../utils/constants";
import { GET_MAIN_TRANSACTIONS } from "../../../utils/constants";
import { useCustomToast } from "../../../components/toast/useCustomToast";

const Users = () => {
  const [totalBalance, setTotalBalance] = useState(0);
  const [currentBalance, setCurrentBalance] = useState({ amount: 0, growth_rate: 0 });
  const [totalCashout, setTotalCashout] = useState({ amount: 0, growth_rate: 0 });
  const [totalDeposit, setTotalDeposit] = useState({ amount: 0, growth_rate: 0 });
  const [transactions, setTransactions] = useState([]);
  const [isWalletLogsLoading, setIsWalletLogsLoading] = useState(true);
const showToast = useCustomToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseTotalBalance = await axios.get(GET_MAIN_BALANCE);
        setTotalBalance(responseTotalBalance.data.data);
        

        const responseCurrentBalance = await axios.get(GET_MAIN_CURRENT);
        setCurrentBalance(responseCurrentBalance.data.data);

        const responseTotalCashout = await axios.get(GET_MAIN_WITHDRAWAL);
        setTotalCashout(responseTotalCashout.data.data);

        const responseTotalDeposit = await axios.get(GET_MAIN_DEPOSIT);
        setTotalDeposit(responseTotalDeposit.data.data);
      
      const responseTransactions = await axios.get(GET_MAIN_TRANSACTIONS);
        setTransactions(responseTransactions.data.data);
      } catch (error) {
       
        if (error.response) {
            const { status, message, errors } = error.response.data;
            if (status === "Error") {
                if (message && errors.length === 0) {
                    showToast(message, 'error');
                } else if (message && errors.length > 0) {
                    showToast(errors[0], 'error');
                }
            }
        }
      }
      finally {
        setIsWalletLogsLoading(false);
      }
    };

    fetchData();
  }, []);
  const formatAmount = (amount) => {
    if (amount === undefined || amount === null) {
      // Provide a default value or handle the case where amount is undefined or null
      return 'N/A';
    }
    return amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
  const walletInfoProps = {
    title: 'Main Wallet Balance',
    amount: `NGN ${formatAmount(totalBalance)}`,
  };

  return (
    <div className=''>
      <WalletInfo walletInfoProp={walletInfoProps} />
      <WalletBreakDown
        walletBalance={formatAmount(totalBalance)}
        currentBalance={formatAmount(currentBalance.amount)}
        totalCashout={formatAmount(totalCashout.amount)}
        totalDeposit={formatAmount(totalDeposit.amount)}
        currentGrowthRate={currentBalance.growth_rate}
        totalCashoutGrowthRate={totalCashout.growth_rate}
        totalDepositGrowthRate={totalDeposit.growth_rate}
      />
      <div className="flex justify-center items-center mt-10">
      {isWalletLogsLoading ? (
        <ThreeDots fill='#1F1474'/> 
      ) : (
        <WalletLogs transactions={transactions} />
      )}
      </div>
     
    </div>
  );
};

export default Users;
